import React from 'react';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

import ChatsManager from './Components/ChatsManager';
import Login from './Components/Login';
import {
  Spinner,
  LoadingWrapper,
  AppWrapper,
  MaintainceModeHeader,
  MaintainceLogo,
} from './styled-components';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: 'ryco-bot.firebaseapp.com',
  projectId: 'ryco-bot',
  storageBucket: 'ryco-bot.appspot.com',
  messagingSenderId: '925507892755',
  appId: '1:925507892755:web:223f4ddc09ea01c91bf1e6',
  measurementId: 'G-SZ0QT1CSEY',
};

const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);

const auth = getAuth();
const db = getFirestore(firebase);

function App() {
  const [currentUser, setCurrentUser] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  //onLoad redirect to https://www.app.ryco.io/auth/createAccount
  React.useEffect(() => {
    window.location.href = 'https://www.app.ryco.io/auth/createAccount';
  }, []);

  return;

  const hasAccess = (email) => {
    // const whiteListedDomains = [
    //   '@ryco.io',
    //   'jordanxcallaway@gmail.com',
    //   'lvtalamas@gmail.com',
    //   'tylerframe8@gmail.com',
    //   '@ccaeducate.me',
    //   'brian.curriculum.workspace@gmail.com',
    //   'joepacenh@gmail.com',
    //   'jdouganderson@gmail.com',
    //   'Dr.kent@lakemontessori.com',
    //   'cowenk@gmail.com',
    // ];

    // for (let i = 0; i < whiteListedDomains.length; i++) {
    //   if (email.includes(whiteListedDomains[i])) {
    //     return true;
    //   }
    // }

    if (email) {
      return true;
    }

    auth.signOut();
    window.alert('You do not have access to this app');
    return false;
  };

  auth.onAuthStateChanged(function (user) {
    if (user) {
      if (hasAccess(user.email)) {
        setCurrentUser(user);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  });

  if (
    process.env.REACT_APP_MAINTENANCE_MODE === 'true' &&
    auth?.currentUser?.email !== 'matt@ryco.io'
  ) {
    return (
      <AppWrapper>
        <MaintainceLogo />
        <MaintainceModeHeader>
          Sorry, we are currently undergoing maintenance. <br /> Please check
          back later.
        </MaintainceModeHeader>
      </AppWrapper>
    );
  }

  if (loading) {
    return (
      <LoadingWrapper>
        <Spinner />
        Loading...
      </LoadingWrapper>
    );
  }

  return (
    <AppWrapper>
      {currentUser ? (
        <ChatsManager db={db} auth={auth} />
      ) : (
        <Login auth={auth} db={db} />
      )}
    </AppWrapper>
  );
}

export default App;
export { firebase, analytics };
